import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Empty, MenuProps, Modal, Table } from 'antd';
import {
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  MinusCircleOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CourseInstanceAttendee,
  CourseInstanceAttendeeStatus,
} from '../types/CourseInstance';
import * as API from '../api';
import { useAppBar } from '../context/AppBar';
import colors from '../theme/colors';
import CreateCourseInstanceAttendeeModal from '../components/CreateCourseInstanceAttendeeModal';
import EditCourseInstanceAttendeeModal from '../components/EditCourseInstanceAttendeeModal';
import { CourseInstanceAttendeeStatusBox } from '../components/CourseInstanceAttendeeStatusBox';

type CourseInstanceAttendeesParams = {
  id: string;
};

const CourseInstanceAttendees = () => {
  const navigate = useNavigate();
  const params = useParams<CourseInstanceAttendeesParams>();
  const id = Number(params.id);

  const attendeesQueryKey = ['getCourseInstanceAttendees', id];
  const attendeesQuery = useQuery({
    queryKey: attendeesQueryKey,
    queryFn: () => API.getCourseInstancesAttendees(id),
    retry: false,
  });

  const attendees = attendeesQuery.data?.data;

  const [showCreateAttendeeModal, setShowCreateAttendeeModal] = useState(false);
  const [editAttendee, setEditAttendee] = useState<CourseInstanceAttendee>();
  const [selectedAttendees, setSelectedAttendees] = useState<
    CourseInstanceAttendee[]
  >([]);
  const { t } = useTranslation();
  const [, setAppBarInterface] = useAppBar();
  const [showDigitalCourseColumn, setShowDigitalCourseColumn] = useState(false);

  const [isMappedInElsa, setIsMappedInElsa] = useState(false);

  useEffect(() => {
    API.getCourseInstance(id).then(({ data }) => {
      setAppBarInterface({
        title: data.course.name,
        showBackButton: true,
      });
      setShowDigitalCourseColumn(
        !!data.elsaTrainingSessionId &&
          data.hasPrerequisiteDigitalCourse === true,
      );
      setIsMappedInElsa(!!data.elsaTrainingSessionId);
    });
  }, [id, setAppBarInterface]);

  useEffect(() => {
    if (attendeesQuery.isError) {
      navigate(`/kurstillfallen/${id}`);
    }
  }, [attendeesQuery.isError, navigate, id]);

  const deleteUsers = (
    idsToRemove: (
      | CourseInstanceAttendee['id']
      | CourseInstanceAttendee['elsaId']
    )[],
  ) => {
    const attendees = idsToRemove.map((id) => {
      if (typeof id === 'number') {
        return {
          id: id,
          elsaId: undefined,
        };
      } else {
        return {
          id: undefined,
          elsaId: id,
        };
      }
    });

    API.deleteCourseInstancesAttendees(id, attendees).then(() => {
      attendeesQuery.refetch();
      setSelectedAttendees([]);
    });
  };

  const showConfirmDeletion = () => {
    Modal.confirm({
      title: t('common.delete'),
      icon: <ExclamationCircleFilled />,
      centered: true,
      content: t('views.CourseInstanceAttendees.deleteBatch', {
        count: selectedAttendees.length,
      }),
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      onOk() {
        deleteUsers(
          selectedAttendees.map((attendee) =>
            isMappedInElsa ? attendee.elsaId : attendee.id,
          ),
        );
      },
    });
  };

  const onStatusUpdate = (newStatus?: CourseInstanceAttendeeStatus) => {
    const attendeesWithNewStatuses = selectedAttendees.map((attendee) => ({
      id: attendee.id,
      elsaId: attendee.elsaId,
      name: attendee.name,
      comment: attendee.comment,
      email: attendee.email,
      status: newStatus,
    }));

    API.updateCourseInstanceAttendees(id, attendeesWithNewStatuses).then(() =>
      attendeesQuery.refetch(),
    );
  };

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: CourseInstanceAttendee[],
    ) => {
      setSelectedAttendees(selectedRows);
    },
  };

  const renderRowMenuItems = (
    id: CourseInstanceAttendee['id'] | CourseInstanceAttendee['elsaId'],
  ) => {
    return [
      {
        key: `edit-${id}`,
        label: (
          <div
            className="flex gap-2 items-center"
            onClick={() =>
              setEditAttendee(
                attendees?.find((attendee) =>
                  isMappedInElsa ? attendee.elsaId === id : attendee.id === id,
                ),
              )
            }>
            <EditOutlined style={{ color: colors.safeLifeDarker }} />
            {t('common.edit')}
          </div>
        ),
      },
      {
        key: `delete-${id}`,
        label: (
          <div
            className="flex gap-2 items-center"
            onClick={() => deleteUsers([id])}>
            <UserDeleteOutlined style={{ color: colors.warning }} />
            {t('common.delete')}
          </div>
        ),
      },
    ];
  };

  const renderPrerequisiteDigitalCourseStatus = (
    attendee: CourseInstanceAttendee,
  ) => {
    if (!attendee.prerequisiteDigitalCourseCompletedPercentage) {
      return (
        <span className="text-red-800 font-semibold">
          {t('views.CourseInstanceAttendees.notStarted')}
        </span>
      );
    }
    if (attendee.prerequisiteDigitalCourseCompletedPercentage === 100) {
      return (
        <span className="text-success font-semibold">
          {t('views.CourseInstanceAttendees.completed')}
        </span>
      );
    }
    return (
      <span className="text-blueGrayDark font-semibold">
        {t('views.CourseInstanceAttendees.inProgress', {
          percentage: attendee.prerequisiteDigitalCourseCompletedPercentage,
        })}
      </span>
    );
  };

  const columns: ColumnsType<CourseInstanceAttendee> = [
    {
      title: t('views.CourseInstanceAttendees.name'),
      dataIndex: 'name',
      render: (name: string, attendee: CourseInstanceAttendee) => {
        if (showDigitalCourseColumn) {
          return (
            <div className="flex flex-col">
              <span className="font-medium">{name}</span>
              <div className="flex flex-col text-xs">
                <span className="text-blueGrayDark">
                  {t('views.CourseInstanceAttendees.digitalCourse')}
                </span>
                {renderPrerequisiteDigitalCourseStatus(attendee)}
              </div>
            </div>
          );
        } else {
          return name;
        }
      },
    },
    {
      dataIndex: 'status',
      render: (status: CourseInstanceAttendeeStatus) => (
        <CourseInstanceAttendeeStatusBox status={status} simplified />
      ),
      align: 'right',
    },
    {
      title: '',
      dataIndex: isMappedInElsa ? 'elsaId' : 'id',
      align: 'right',
      render: (
        id: CourseInstanceAttendee['id'] | CourseInstanceAttendee['elsaId'],
      ) => {
        return (
          <Dropdown
            menu={{ items: renderRowMenuItems(id) }}
            placement="bottomRight"
            trigger={['click']}
            className="inline-block">
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const batchEditMenuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.Passed}
          onClick={() => onStatusUpdate(CourseInstanceAttendeeStatus.Passed)}
        />
      ),
    },
    {
      key: '2',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.Failed}
          onClick={() => onStatusUpdate(CourseInstanceAttendeeStatus.Failed)}
        />
      ),
    },
    {
      key: '3',
      label: (
        <CourseInstanceAttendeeStatusBox
          status={CourseInstanceAttendeeStatus.DidNotParticipate}
          onClick={() =>
            onStatusUpdate(CourseInstanceAttendeeStatus.DidNotParticipate)
          }
        />
      ),
    },
    {
      key: '3',
      label: (
        <div
          className="flex gap-2 items-center"
          onClick={() => onStatusUpdate(undefined)}>
          <MinusCircleOutlined style={{ color: colors.safeLifeDarker }} />
          {t('views.CourseInstanceAttendees.statusClear')}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white h-full view-max-width max-w-sm">
      <div className="py-6 px-4 flex justify-between items-center">
        <div className="text-2xl font-semibold">
          {t('views.CourseInstanceAttendees.title')}
        </div>
        <Button
          type="primary"
          icon={
            <UserAddOutlined onClick={() => setShowCreateAttendeeModal(true)} />
          }
        />
      </div>
      <Table
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common.noData')}
            />
          ),
        }}
        dataSource={attendees}
        columns={columns}
        rowKey={(record) => (isMappedInElsa ? record.elsaId : record.id)!}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: (record) => <span>{record.comment}</span>,
          rowExpandable: (record) => !!record.comment,
        }}
        className="pb-16"
      />
      <div
        className="absolute left-0 bottom-0 w-full flex justify-center"
        style={{
          backgroundColor: colors.safeLifeDarker,
          color: colors.white,
        }}>
        <div>
          <div
            className={`p-4 flex gap-2 items-center ${
              selectedAttendees.length == 0 && 'hidden'
            }`}>
            <div
              className="px-2 rounded-sm"
              style={{
                backgroundColor: colors.white,
                color: colors.textDefault,
              }}>
              {selectedAttendees.length}
            </div>
            {t('views.CourseInstanceAttendees.attendeesSelected', {
              count: selectedAttendees.length,
            })}
            <Dropdown
              menu={{ items: batchEditMenuItems }}
              placement="bottomRight"
              trigger={['click']}>
              <Button icon={<DownOutlined />}>
                {t('views.CourseInstanceAttendees.changeStatus')}
              </Button>
            </Dropdown>
            <Button
              danger
              type="primary"
              onClick={showConfirmDeletion}
              icon={<UsergroupDeleteOutlined />}
            />
          </div>
        </div>
      </div>
      <CreateCourseInstanceAttendeeModal
        courseInstanceId={id}
        isMappedInElsa={isMappedInElsa}
        open={showCreateAttendeeModal}
        onCancel={() => setShowCreateAttendeeModal(false)}
        onCreateAttendees={() => attendeesQuery.refetch()}
      />
      <EditCourseInstanceAttendeeModal
        attendee={editAttendee}
        courseInstanceId={id}
        isMappedInElsa={isMappedInElsa}
        onEditAttendees={() => attendeesQuery.refetch()}
        onCancel={() => setEditAttendee(undefined)}
      />
    </div>
  );
};

export default CourseInstanceAttendees;
